import GetCookies from 'hooks/GetCookies';
import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import io from 'socket.io-client';
import {
  getAllNotification,
  setIsnotificationData,
  setNotificationData,
} from 'store/reducers/Notification/notification.slice';

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};
export const SocketProvider = ({ children }) => {
  const userData = GetCookies('UserSessionCRM');
  const userSession = userData ? JSON.parse(atob(userData)) : null;
  const sessionData = GetCookies('TokenCRM');
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [response, setResponse] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const socketIo = io(process.env.REACT_APP_API_URL, {
      transports: ['websocket', 'polling', 'flashsocket'],
    });
    setSocket(socketIo);
    // Listen for connection
    socketIo.on('connect', () => {
      console.log('connected');

      setIsConnected(true);
      console.log('Socket Connected to server with ID:', socketIo);
      if (socketIo.connected) {
        socketIo.emit('req', {
          en: 'ADMIN_JU',
          data: {
            user_id: userSession._id,
            token: sessionData,
          },
        });
      }
    });
    socketIo.on('res', res => {
      setResponse(res);
      console.log('res', res);
    });
    socketIo.on('connect_error', error => {
      console.log('Disconnected from socket server', error);
    });

    socketIo.on('notification_list', en => {
      console.log('en', en);
      if (en?.is_bpe) dispatch(getAllNotification());
      else dispatch(setNotificationData(en?.listData));
    });

    // Handle disconnection
    socketIo.on('disconnect', () => {
      setIsConnected(false);
      console.log('Disconnected from server');
    });
    return () => {
      socketIo.disconnect();
    };
  }, []);
  return (
    <SocketContext.Provider value={{ socket, isConnected, response }}>
      {children}
    </SocketContext.Provider>
  );
};
