import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showMessage } from '../common.slice';
import axios from 'axios';

const initialState = {
  notifications: [],
  notificationLoading: false,
};

export const getAllNotification = createAsyncThunk(
  'admin/get-all-notification',
  (asa, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get('admin/notification/list')
        .then(res => {
          if (res.data.err === 0) {
            resolve(res.data.data.listData);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const updateNotification = createAsyncThunk(
  'admin/update-notification',
  (props, { dispatch }) => {
    return new Promise((resolve, reject) => {
      const { _id, data } = props;
      console.log('props', props);

      axios
        .put(`admin/notification/update`, props)
        .then(res => {
          if (res.data.err === 0) {
            // dispatch(
            //   showMessage({ message: res.data.msg, varient: 'success' }),
            // );
            resolve(res?.data?.data);
          } else {
            dispatch(showMessage({ message: res?.data?.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error?.response?.data?.msg }));
          reject(error);
        });
    });
  },
);

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setnotificationLoading: (state, action) => {
      state.notificationLoading = action.payload;
    },
    setNotificationData: (state, action) => {
      state.notifications = action.payload;
    },
  },
  extraReducers: {
    [getAllNotification.pending]: state => {
      state.notificationLoading = true;
    },
    [getAllNotification.rejected]: state => {
      state.notificationLoading = false;
    },
    [getAllNotification.fulfilled]: (state, action) => {
      state.notifications = action.payload || [];
      state.notificationLoading = false;
    },
  },
});
export const { setNotificationData } = notificationSlice.actions;
export default notificationSlice.reducer;
