import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from 'lodash';
import { showMessage } from '../../../common.slice';

const initialState = {
  watchList: [],
  getWatchListFilter: {},
  watchListLoading: false,
};

export const getWatchListData = createAsyncThunk(
  'admin/get-watch-list',
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('admin/watch_list', data)
        .then(res => {
          if (res.data.err === 0) {
            if (res.data.data) {
              resolve(res.data.data);
            } else {
              resolve([]);
            }
          } else {
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);
export const getWatchListFilterData = createAsyncThunk(
  'admin/get-watch-list-filter',
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get('admin/watch_list_filter')
        .then(res => {
          if (res.data.err === 0) {
            if (res.data.data) {
              resolve(res.data.data);
            } else {
              resolve([]);
            }
          } else {
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const watchListSlice = createSlice({
  name: 'watchlist',
  initialState,
  reducers: {
    resetWatchlist: (state, action) => {
      state.watchList = [];
      state.watchListLoading = false;
    },
  },
  extraReducers: {
    [getWatchListData.pending]: state => {
      state.watchListLoading = true;
    },
    [getWatchListData.rejected]: state => {
      state.watchList = [];
      state.watchListLoading = false;
    },
    [getWatchListData.fulfilled]: (state, action) => {
      state.watchList = action.payload;
      state.watchListLoading = false;
    },
    [getWatchListFilterData.pending]: state => {
      state.watchListLoading = true;
    },
    [getWatchListFilterData.rejected]: state => {
      state.getWatchListFilter = {};
      state.watchListLoading = false;
    },
    [getWatchListFilterData.fulfilled]: (state, action) => {
      state.getWatchListFilter = action.payload;
      state.watchListLoading = false;
    },
  },
});
export const { resetWatchlist } = watchListSlice.actions;

export default watchListSlice.reducer;
