import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateNotification } from 'store/reducers/Notification/notification.slice';

export default function Notification({ notificationClick }) {
  const { notifications } = useSelector(({ notification }) => notification);
  const dispatch = useDispatch();
  const handleViewall = params => {
    const payload = {
      mark_as_read: true,
      deleted: false,
      notification_id: null,
      update_all: true,
    };
    dispatch(updateNotification(payload));
  };
  const handleClearall = params => {
    const payload = {
      mark_as_read: false,
      deleted: true,
      notification_id: null,
      update_all: true,
    };
    dispatch(updateNotification(payload));
  };
  const handleDelete = params => {
    const payload = {
      mark_as_read: false,
      deleted: true,
      notification_id: params._id,
      update_all: false,
    };
    dispatch(updateNotification(payload));
  };
  const openNoti = params => {
    const payload = {
      mark_as_read: true,
      deleted: false,
      notification_id: params._id,
      update_all: false,
    };
    dispatch(updateNotification(payload));
  };

  return (
    <div>
      {' '}
      <ul
        className={`dropdown-menu dropdown-menu-right notification_menu ${
          notificationClick ? 'show' : ''
        }`}
      >
        {notifications.map((noti, index) => (
          <>
            <li
              key={index}
              className="dropdown-item d-flex justify-content-between"
              style={{ background: !noti.is_read ? '#fe3d6714' : '' }}
              onClick={() => openNoti(noti)}
            >
              {/* {noti.content} */}
              <span dangerouslySetInnerHTML={{ __html: noti.content }}></span>
              <span
                onClick={() => handleDelete(noti)}
                className="cursor-pointer"
              >
                <i class="fa-solid fa-xmark"></i>
              </span>
            </li>
          </>
        ))}
        {notifications.length === 0 ? (
          <li className="dropdown-item text-center mt-3">
            <span>No Notification</span>
          </li>
        ) : (
          <li>
            <div className="d-flex justify-content-between notification_button">
              <span onClick={handleViewall}>View all</span>
              <span onClick={handleClearall}>Clear all</span>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
}
